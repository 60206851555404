<template>
  <div class="p-5 font-size-30 text-center">
    <div class="font-weight-bold mb-3">Halaman tidak dapat diakses</div>
    <div>Halaman ini bisa terjadi karena Anda tidak memiliki akses pada halaman ini</div>
    <div class="font-weight-bold font-size-70 mb-1">403 —</div>
    <router-link to="/" class="btn btn-outline-primary width-100">
      Kembali
    </router-link>
  </div>
</template>

